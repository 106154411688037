import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../service/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'app/service/user.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly = false;
  user: any;

  currentTheme = 'default';

  userMenu = [{ title: 'Sair' }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private nbMenuService: NbMenuService,
    private themeService: NbThemeService,
    private authService: AuthService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    const userName: any = this.authService.getUserName();
    if (userName !== undefined) {
      this.user = { name: userName };
    }

    this.userService.getLoggedUser().pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        const loggedUser = response.data;
        if (loggedUser?.ruralProducer) {
          this.user = {name: loggedUser.ruralProducer.name};
        } else if (loggedUser?.company) {
          this.user = {name: loggedUser.company.name};
        } else if (loggedUser?.employee) {
          this.user = {name: loggedUser.employee.name};
        } else {
          this.user = {name: loggedUser?.client?.name};
        }
      }
    })

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe(themeName => (this.currentTheme = themeName));

    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'context-user'),
        map(({ item: { title } }) => title)
      )
      .subscribe(title => this.userContexMenuSwitch(title));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  userContexMenuSwitch(title: string) {
    if (title === 'Sair') {
      this.logout();
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    localStorage.removeItem('access');
    this.router.navigateByUrl('/');
  }
}
