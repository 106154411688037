import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'app/service/user.service';
import { INewUser } from 'app/shared/interfaces/user.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
  formNewPassword!: FormGroup;
  userId: number;
  hide = true;
  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.userId = Number(params['id']);
      })
    );
    this.createForm();
  }

  createForm() {
    this.formNewPassword = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.min(3)]),
    });
  }

  onSubmit(value: {password: string}) {
    const data: INewUser = {
      id: this.userId,
      password: value.password,
    };
    this.subscriptions.push(
      this.userService.updateUser(data).subscribe({
        next: () => {
          this.toastrService.show('Senha atualizada com sucesso!', 'Confirmação');
          this.router.navigateByUrl('/');
        },
        error: error => {
          console.error(error);
          this.toastrService.error('Falha ao atualizar senha!', 'Erro')
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
