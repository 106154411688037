import { Injectable } from '@angular/core';
import { ObservableHttpService } from './observable-http.service';
import { Observable } from 'rxjs';
import { IUserGETFilters, IUserLogin, INewUser } from 'app/shared/interfaces/user.interface';
import { IEntityToSelect, IPaging, IResponse } from 'app/shared/interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly entity_url = 'user';

  constructor(private readonly observableHttpService: ObservableHttpService) {}

  createUser(user: INewUser): Observable<IResponse<INewUser>> {
    return this.observableHttpService.post(this.entity_url, user);
  }

  getAllUsers(pagination?: IPaging, filters?: IUserGETFilters): Observable<IResponse<IUserLogin[]>> {
    return this.observableHttpService.get<IResponse<IUserLogin[]>>(this.entity_url, {...pagination, ...filters});
  } 

  getAccessGroupToSelect(): Observable<IResponse<IEntityToSelect[]>> {
    return this.observableHttpService.get<IResponse<IEntityToSelect[]>>(this.entity_url+'/access-group/select');
  }
  
  deleteUser(id: number): Observable<void> {
    return this.observableHttpService.delete<void>(`${this.entity_url}/${id}`)
  }

  updateUser(user: INewUser): Observable<IResponse<INewUser>> {
    return this.observableHttpService.patch(`${this.entity_url}/${user.id}`, user);
  }

  getUserById(id: number): Observable<IResponse<IUserLogin>> {
    return this.observableHttpService.get<IResponse<IUserLogin>>( `${this.entity_url}/${id}`)
  }

  getLoggedUser(): Observable<IResponse<IUserLogin>> {
    return this.observableHttpService.get<IResponse<IUserLogin>>('me');
  }
}
