import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tempEnvironment } from '../../environments/environment';
import { sanitizeObject } from '../shared/helpers/sanitize-object.helper';
import { IResponse } from 'app/shared/interfaces/response.interface';

const API_URL = tempEnvironment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ObservableHttpService {
  constructor(private http: HttpClient) {}

  get<T>(url: string, params?: object): Observable<T> {
    return this.http.get<T>(`${API_URL}/${url}`, {
      params: new HttpParams({ fromObject: { ...sanitizeObject(params) } }),
    });
  }

  post<T>(url: string, data: T): Observable<IResponse<T>> {
    return this.http.post<IResponse<T>>(`${API_URL}/${url}`, data);
  }

  put<T>(url: string, data: T): Observable<T> {
    return this.http.put<T>(`${API_URL}/${url}`, data);
  }

  patch<T>(url: string, data?: T): Observable<IResponse<T>> {
    return this.http.patch<IResponse<T>>(`${API_URL}/${url}`, data);
  }

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(`${API_URL}/${url}`);
  }

  postLogin<Req, Res>(url: string, data: Req): Observable<Res> {
    return this.http.post<Res>(`${API_URL}/${url}`, data);
  }
}
